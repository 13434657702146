<template>
  <div id="dashboard-patrol">
    <!-- Table Container Card -->
    <b-card
      no-body
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Entries</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
            <b-button
              v-if="$can('create', 'area')"
              variant="primary"
              :to="{ name: 'master-data-area-create'}"
              class="mr-2"
            >
              <span class="text-nowrap">Create</span>
            </b-button>
          </b-col>
          <!-- Search -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
          >
            <div>
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
            </div>
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="refAreaListTable"
        :items="dataArea"
        :fields="tableColumns"
        class="position-relative"
        :filter="searchQuery"
        striped
        responsive
        show-empty
        empty-text="No matching records found"
      >
        <template #cell(actions)="data">
          <b-button
            v-if="$can('edit', 'area')"
            variant="primary"
            :to="{ name: 'master-data-area-edit', params: { id: data.item.id } }"
            class="mr-2 mb-1"
          >
            <span class="text-nowrap">Edit</span>
          </b-button>
          <b-button
            variant="primary"
            @click="viewCheckpoint(data.item.id)"
          >
            <span>View Checkpoints</span>
          </b-button>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :per-page="perPage"
              :total-rows="totalArea"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <!-- Show View Checkpoints Modal -->
    <b-modal id="bv-modal-example" size="xl" hide-footer>
      <b-table-simple>
        <b-thead>
          <b-th>Site</b-th>
          <b-th>Description</b-th>
          <b-th>Checkpoint Name</b-th>
          <b-th>Action</b-th>
        </b-thead>
        <draggable  
          :class="{[`cursor-grabbing`]: dragging === true}" 
          v-model="checkPointList" 
          group="fruits" 
          @start="dragging = true" 
          @end="dragging = false" 
          @change="dragChanged" 
          tag="tbody" 
        >
          <b-tr v-if="checkPointList.length == 0">
            <b-td colspan="4" style="text-align: center;">No Data</b-td>
          </b-tr>
          <b-tr v-else v-for="element in checkPointList" :key="element.id">
            <!-- <b-td>
              <div class="handle">
                <b-icon  icon="grip-horizontal" /></div>
            </b-td> -->
            <b-td>{{element.area_name}}</b-td>
            <b-td>{{element.data.description}}</b-td>
            <b-td>{{element.name}}</b-td>
            <b-td>
              <b-button
                variant="primary"
                @click="downloadQR(element.id)"
              >
                <span>View Checkpoints</span>
              </b-button>
            </b-td>
          </b-tr>
        </draggable>
      </b-table-simple>
    </b-modal>
    <!-- Show Qr Modal -->
    <b-modal id="bv-modal-qr" centered size="md" hide-footer>
      <qrcode-vue hidden :value="valueQR" :size="sizeQR" level="H" />
      <qrcode-vue class="mb-2" :value="valueQR" :size="450" level="H" />
      <b-button
        variant="primary"
        @click="printQR"
        style="text-align:center;"
      >
        <span>Print QR</span>
      </b-button>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BTable, BPagination, BButton,
  BModal,
  BTableSimple,
  BThead,
  BTh,
  BTr,
  BTd,
  BIcon,
  BIconGripHorizontal,
} from 'bootstrap-vue'
import draggable from 'vuedraggable'
import vSelect from 'vue-select'
import {
  onUnmounted, ref, watch, computed,
} from '@vue/composition-api'

//QR Code
import QrcodeVue from 'qrcode.vue'

import store from '@/store'
// import areaList from '@/views/apps/master-data/area/area-list/AreaList'
import areaStoreModule from '@/views/apps/master-data/area/areaStoreModule'

// import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BPagination,
    BButton,
    BModal,
    BTableSimple,
    BThead,
    BTh,
    BTr,
    BTd,
    BIcon,
    BIconGripHorizontal,
    draggable,

    vSelect,
    QrcodeVue,
  },
  data: function() {
    return{
      dragging: false,
      checkPointList: [],
      areaId: 0,
      valueQR: '',
      sizeQR: 1270,
    }
  },
  methods: {
    viewCheckpoint(id){
      this.areaId = id
      store
        .dispatch('app-area/fetchCheckPointList', {
          area_id:id,
        })
        .then(response => {
          console.log('ini app rea poin list')
          console.log(response.data.data)
          this.checkPointList = response.data.data
          this.$bvModal.show('bv-modal-example')
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching checkpoint list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    dragChanged(eventData) {
      console.log(this.checkPointList)
      // if (eventData.moved) {
      //   console.log(
      //     "Moved",
      //     eventData.moved.element,
      //     eventData.moved.newIndex,
      //     eventData.moved.oldIndex
      //   );
      // }
      let checkPointListTemp = this.checkPointList
      this.checkPointList.forEach( function(el, index){
        checkPointListTemp[index].sequence = index + 1
      })
      store
        .dispatch('app-area/fetchCheckPointList', {
          area_id: this.areaId,
          sequences: JSON.stringify(checkPointListTemp)
        })
        .then(response => {
          console.log('ini app rea poin list')
          console.log(response.data.data)
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching checkpoint list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    downloadQR(id){
      store
        .dispatch('app-area/fetchQR', {
          id:id,
        })
        .then(response => {
          console.log('ini app rea poin qr')
          console.log(response.data.data)
          this.valueQR = response.data.data.qr_text
          this.$bvModal.show('bv-modal-qr')
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching QR ',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    printQR() {
      let QRSrc = document.getElementsByTagName('canvas')[0].toDataURL('image/png');
      this.downloadQrCode(QRSrc)    
    }
  },
  setup() {
    const AREA_APP_STORE_MODULE_NAME = 'app-area'

    // Register module
    if (!store.hasModule(AREA_APP_STORE_MODULE_NAME)) store.registerModule(AREA_APP_STORE_MODULE_NAME, areaStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(AREA_APP_STORE_MODULE_NAME)) store.unregisterModule(AREA_APP_STORE_MODULE_NAME)
    })

    // Table Handlers
    const tableColumns = [
      { key: 'name', label: 'Site', sortable: true },
      { key: 'description', sortable: true },
      { key: 'pic_name', sortable: true },
      { key: 'pic_phone', sortable: true },
      { key: 'modified_by', label: 'last_modified_by', sortable: true },
      { key: 'modified_at', label: 'last_modified_date', sortable: true },
      { 
        key: 'status',  
        sortable: true,
        formatter: (val) => val == 1 ? 'Enabled' : 'Disabled',
        filterByFormatted: true,
      },
      { key: 'actions' },
    ]

    // Use toast
    const toast = useToast()

    const refAreaListTable = ref(null)
    const dataArea = ref(null)

    const perPage = ref(10)
    const totalArea = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('id')
    const isSortDirDesc = ref(true)

    const dataMeta = computed(() => {
      const localItemsCount = refAreaListTable.value ? refAreaListTable.value.localItems.length : 0
      return {
        from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalArea.value,
      }
    })

    const refetchData = () => {
      refAreaListTable.value.refresh()
    }

    watch([currentPage, perPage], () => {
      fetchArea()
      refetchData()
    })

    const fetchArea = (ctx, callback) => {
      store
        .dispatch('app-area/fetchAreas', {
          // q: searchQuery.value,
          perPage: perPage.value,
          currentPage: currentPage.value,
          // sortBy: sortBy.value,
          // sortDesc: isSortDirDesc.value,
          // status: statusFilter.value,
        })
        .then(response => {
          console.log('ini app rea')
          console.log(response.data.data)
          const data = response.data.data.list
          // callback(data)
          dataArea.value = response.data.data.list
          totalArea.value = response.data.data.total
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching area list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    fetchArea()

    // handle qr code print
    const downloadQrCode = (canvasDataImage) => {
      let canvasImage = canvasDataImage
      console.log(canvasImage)
      let image = new Image()
      image.src = canvasImage
      image.onload = function() {
        let jcanvas = document.createElement('canvas')
        jcanvas.width = 2401
        jcanvas.height = 3383
        let ctx = jcanvas.getContext('2d')
        ctx.drawImage(image, jcanvas.width / 4.3, jcanvas.height / 3.06)
        let w = window.open()
        let limage = new Image()
        limage.src = jcanvas.toDataURL()
        limage.style = 'width:95%;margin:auto;padding:auto;'
        limage.onload = function() {
          w.document.write(limage.outerHTML)
          w.print()
        }
      }
    }
    return {
      // fetchArea,
      dataArea,
      tableColumns,
      perPage,
      currentPage,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refAreaListTable,
      toast,
      downloadQrCode,
      totalArea,

      refetchData,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}

</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
