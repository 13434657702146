import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchAreas(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${this.state.customConfig.baseURL}/web/area/get`, {
            apikey: this.state.customConfig.token,
            perPage: payload.perPage,
            currentPage: payload.currentPage,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCheckPointList(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${this.state.customConfig.baseURL}/web/area/checkpointlist`, {
            apikey: this.state.customConfig.token,
            area_id: payload.area_id,
            sequences: payload.sequences
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchQR(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${this.state.customConfig.baseURL}/web/checkpoint/showqr`, {
            apikey: this.state.customConfig.token,
            id: payload.id,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
